.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #f12c32;
}

.blackSmallDot {
  width: 5px;
  height: 5px;
  background-color: #000000;
}