.container {
  width: 100%;
  display: flex;
  flex-direction: column;

  span {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.47px;
    color: #2d2d2d;
    text-transform: capitalize;
    text-align: center;

    &:first-child {
      font-weight: normal;
    }
  }
}