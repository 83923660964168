.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  .close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 32px 15px 31px 0;
  }
  .menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    button {
      margin-bottom: 50px;
    }
  }
}
