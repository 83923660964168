.container {
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.14);
  .boxMenu {
    height: 100%;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 968px) {
      display: none;
    }
  }
}
