.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .wrapper {
    width: 1100px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @media only screen and (max-width: 1200px) {
      width: 1000px;
      padding: 0 20px;
    }

    @media only screen and (max-width: 968px) {
      width: 100%;
    }
  }
  .wrapperFooterLeft {
    display: none;
    @media only screen and (max-width: 968px) {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 31px;
      position: relative;
      margin-top: 70px;
    }
    @media only screen and (max-width: 768px) {
      margin-top: 30px;
    }
  }

  .wrapperHeader {
    @media only screen and (max-width: 1200px) {
      padding: 0 20px;
    }
    @media only screen and (max-width: 968px) {
      margin-top: 10px;
      margin-bottom: 16px;
      padding: 0 0 0 20px;
    }
  }

  .wrapperFooter {
    @media only screen and (max-width: 968px) {
      padding: 0 20px;
    }
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 31px;
    }
  }

  .wrapperHome {
    flex-direction: column;
    justify-content: flex-start;
    @media only screen and (max-width: 1200px) {
      width: 1000px;
    }

    @media only screen and (max-width: 968px) {
      width: 100%;
      padding: 0;
    }
  }

  .wrapperInfoPage {
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 10px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px rgba(151, 151, 151, 0.5);
    background-color: #ffffff;
    position: relative;
    top: -63px;
    z-index: 10;
    margin-bottom: 60px;
    padding: 22px 22px 80px 14px;

    @media only screen and (max-width: 968px) {
      margin: 0 20px 60px;
    }
  }

  .wrapperForm {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 50px 0 0 0;
    @media only screen and (max-width: 450px) {
      padding: 70px 0 100px;
    }
    h3 {
      font-size: 30px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 2px;
      text-align: center;
      color: #2d2d2d;
      text-transform: uppercase;
      @media only screen and (max-width: 450px) {
        font-size: 25px;
      }
    }
  }

  .wrapperColumn {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    @media only screen and (max-width: 1200px) {
      width: 1000px;
      padding: 0 20px;
    }
    @media only screen and (max-width: 968px) {
      width: 100%;
    }
  }

  .wrapperContainerCards {
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 20px;
    margin-bottom: 154px;

    @media only screen and (max-width: 1200px) {
      width: 1000px;
      padding: 20px 20px 0;
    }
    @media only screen and (max-width: 968px) {
      width: 100%;
    }

    @media only screen and (max-width: 768px) {
      justify-content: space-evenly;
    }

    @media only screen and (max-width: 450px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .wrapperCreatePublication {
    width: 1050px;
    display: flex;
    flex-direction: column;
    padding: 30px 0 0;
    @media only screen and (max-width: 1200px) {
      width: 100%;
    }
  }

  .wrapperCreatePublicationHeader {
    // width: 1050px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    // padding: 40px 0;
    position: relative;

    @media only screen and (max-width: 1200px) {
      width: 100%;
      // padding: 47px 20px 47px;
    }

    @media only screen and (max-width: 968px) {
      flex-direction: column;
      // padding: 30px 20px 60px;
    }

    @media only screen and (max-width: 320px) {
      padding: 30px 20px;
    }
  }

  .wrapperCreatePublicationStep {
    width: 1050px;
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    align-items: center;
    @media only screen and (max-width: 1200px) {
      width: 100%;
      padding: 30px 20px;
    }
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      padding: 20px 20px;
    }
  }

  .wrapperDatePublication {
    width: 1050px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px 0 0;
    @media only screen and (max-width: 1200px) {
      width: 100%;
    }
  }
}

.containerHide {
  width: auto;
}

.containerHightAuto {
  height: auto;
}
