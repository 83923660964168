.container {
  width: 100%;
  height: 100%;
  background: rgba(96, 96, 96, 0.4);
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 999;
}

.sidebarContainer {
  display: none;
  @media only screen and (max-width: 968px) {
    display: flex;
  }
}

.sideBar {
  width: 224px;
  height: 100%;
  background-color: #ffffff;
}

.containerOrder {
  height: auto;
  background: rgba(45, 45, 45, 0.7);
  justify-content: center;
  align-items: flex-start;
  padding: 97px 0;
  // width: 100%;
  // height: 100%;
  // background: rgba(96, 96, 96, 0.4);
  // position: absolute;
  // display: flex;
  // justify-content: flex-end;
  // align-items: center;
  // z-index: 999;
}

.template {
  width: 100%;
  height: 100%;
  background: rgba(45, 45, 45, 0.7);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 155px;
  z-index: 999;
  @media only screen and (max-width: 768px) {
    padding: 100px 5px;
  }
}
