.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  .listContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;

    .listItem {
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0.47px;
      color: #2d2d2d;
      text-transform: capitalize;
      text-align: center;

      list-style: none;
    }

    .itemSubtitle {
      font-weight: normal;
      text-transform: uppercase;
      display: inline-block;

      margin: 0.5rem 0 0.25rem;
    }
  }

  span {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.47px;
    color: #2d2d2d;
    text-transform: capitalize;
    text-align: center;

    &:first-child {
      font-weight: bold;
    }
  }
}