.menuBox {
  display: flex;
  align-items: center;
  .dotBox {
    margin: 0 10px;
  }

  @media only screen and (max-width: 968px) {
    flex-direction: row-reverse;
    margin-left: 31px;
  }
}

.mobMenu {
  :nth-child(2) {
    display: none;
  }
  @media only screen and (max-width: 968px) {
    :nth-child(2) {
      display: flex;
    } 
  }
}

.hide {
  display: none;
}
