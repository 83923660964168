.container {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: flex-start;

  @media only screen and (max-width: 968px) {
    display: none;
  }

  .promoBox {
    padding: 10px;
    background-color: #ff0022;
    color: white;
    font-style: italic;
    text-align: center;
    h1 {
      line-height: 2rem;
      font-size: 2rem;
    }
    p {
      font-size: 1.2rem;
    }
  }

  .loginBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 171px;
    .label {
      font-size: 18px;
      line-height: 1.67;
      color: rgba(45, 45, 45, 0.75);
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  .boxInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    background-color: #00438e;

    > div {
      width: 100%;

      &:first-child {
        margin-top: 10px;
      }

      &:last-child {
        margin-bottom: 10px;
      }

      > span {
        width: 100%;
        padding-top: 6px;
        padding-bottom: 6px;
        text-transform: uppercase;
        font-size: 12px;
      }

      > div {
        > span {
          background-color: #00356f;
          padding-top: 6px;
          padding-bottom: 6px;
          text-transform: uppercase;
          font-size: 12px;
        }

        > nav {
          > div,
          > li {
            color: white !important;
            font-size: 1rem;
            padding-top: 6px;
            padding-bottom: 6px;
          }

          > div {
            font-weight: bold !important;
            font-style: italic;
            background-color: #00356f;
            width: 100%;
            text-align: center;
          }
        }
      }
    }

    span {
      color: white;
      font-size: 1rem;
    }
  }

  .logoFooter {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 30px;

    .policeBox {
      display: flex;
      align-items: center;
      margin-top: 10px;
    }
  }
}
