.footer {
  border-top: 1px solid #111;
}

.logoBox {
  position: relative;
  bottom: 44px;
  display: flex;
  align-items: center;

  padding: 20px;
  @media only screen and (max-width: 768px) {
    position: static;
  }
}

.termsBox {
  padding: 20px;
  display: flex;
  align-items: center;
  position: relative;
  bottom: 10px;
  @media only screen and (max-width: 768px) {
    position: static;
  }
  font-size: 20px;
  color: #2d2d2d;
  a {
    color: #2d2d2d;
    font-size: 10px;
  }
  p {
    font-size: 10px;
    margin: 0 5px;
  }
}
