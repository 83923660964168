.container {
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.14);
}
.menuBox {
  display: flex;
  align-items: center;
  @media only screen and (max-width: 968px) {
    display: none;
  }
}

.hide {
  display: none;
  @media only screen and (max-width: 968px) {
    display: none;
  }

  
}

.hideHeader {
  display: none;
  @media only screen and (max-width: 968px) {
    display: flex;
  }
}


