@mixin fontStyle( $fontSize: 10px, $fontWeight:normal, $color:#898989, $fontStyle:normal) {
  font-size: $fontSize;
  font-weight: $fontWeight;
  font-style: $fontStyle;
  color: $color;
  text-decoration: none;
}

.logo {
  a{
    @include fontStyle($fontWeight:bold);
    letter-spacing: 2px;
    text-transform: uppercase;
    cursor: pointer;
    span {
      padding-left: 5px;
    }
    img {
      width: 169px;
      height: 58px;
    }
  }
}

.logo {
  @include fontStyle($fontWeight:bold);
    letter-spacing: 2px;
    text-transform: uppercase;
    cursor: pointer;
    span {
      padding-left: 5px;
    }
    img {
      width: 169px;
      height: 58px;
    }
}

.logoLeftHeader {
  margin-top: 10px;
  a{
    @include fontStyle($fontWeight:bold);
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    span {
      padding-left: 5px;
    }
    img {
      width: 169px;
      height: 58px;
    }
  }
}

.logoLeftHeader {
  margin-top: 10px;
  @include fontStyle($fontWeight:bold);
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    span {
      padding-left: 5px;
    }
    img {
      width: 169px;
      height: 58px;
    }
}

.logoFooter {
  cursor: pointer;
  img {
    width: 172px;
    height: 67px;
  }
}

.headerMenu {
  padding:4px 4px;
  border-bottom: 4px solid transparent;
  a{
    @include fontStyle($fontSize: 18px, $fontWeight:bold, $fontStyle:italic, $color:#f12c32);
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
    @media only screen and (max-width: 968px) {
      font-size: 22px;
      font-style: italic;
      line-height: 2.5;
    }

    @media only screen and (max-width: 450px) {
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 968px) {
    &::first-letter{
      text-transform: capitalize;
    }
  }
}

.headerMenu {
  padding:4px 4px;
  border-bottom: 4px solid transparent;
  @include fontStyle($fontSize: 18px, $fontWeight:bold, $fontStyle:italic, $color:#f12c32);
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
  @media only screen and (max-width: 968px) {
    font-size: 22px;
    font-style: italic;
    line-height: 2.5;
  }

  @media only screen and (max-width: 450px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 968px) {
    &::first-letter{
      text-transform: capitalize;
    }
  }
}

.policyLink {
  @include fontStyle($fontSize: 12px, $fontWeight:bold, $fontStyle:italic, $color:#cccccc);
  text-decoration: underline;
  text-transform: capitalize;
  cursor: pointer;
  transition: 0.3s;
  &:last-child {
    margin-left: 10px;
  }
  &:hover {
    opacity: 0.7;
  }
}

.activeLink {
  border-bottom: 4px solid #000000;
  @media only screen and (max-width: 968px) {
    border-bottom: 4px solid transparent;
  }
}

.createAccount {
  @include fontStyle($fontSize: 18px, $fontWeight:bold, $fontStyle:italic, $color:#f12c32);
  text-align: center;
  cursor: pointer;
  &::first-letter {
    text-transform: capitalize;
  }
  &:hover {
    opacity: 0.7;
  }
}

.dashboardMenu {
  @include fontStyle($fontSize: 16px, $fontWeight:bold);
  height: 100%;
  text-transform: uppercase;
  margin-left: 31px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  letter-spacing: 1px;
  &:first-child {
    @include fontStyle($fontSize: 18px, $fontWeight:bold, $fontStyle:italic, $color:#f12c32);
    text-transform: capitalize;
  }
  a{
    &:hover {
      opacity: 0.5;
    }
  }
}

.dashboardMenu {
  &:hover {
    opacity: 0.5;
  }
}

.linkDashboardMobile {
  cursor: pointer;
}

.activeLinkDashboard {
 background-color: #f5f5f5;
 border-bottom: 3px solid rgba(45, 45, 45, 0.75);
  @media only screen and (max-width: 968px) {
    color: #f12c32;
    background-color: #ffffff;
    border-bottom: 3px solid transparent;
  }
}
