.clear-cache {
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  padding: 10px;
  background-color: bisque;
  border-top-right-radius: 10px;
  cursor: pointer;
  opacity: 0.2;
  transition: all 200ms linear;
  &:hover {
    opacity: 0.75;
  }
}
