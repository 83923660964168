.spinner-overlay {
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  color: #f12c32;

  .spinner-container {
    position: fixed;
    top: 0;
    height: 100vh;
    left: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}