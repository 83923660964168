.textInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  span {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.47px;
    color: #2d2d2d;
    text-transform: capitalize;
    text-align: center;

    &:first-child {
      font-weight: bold;
    }
  }
}
