.layout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  position: relative;
  .main {
    display: flex;
    flex: 1;
    .content {
      width: 100%;
      margin: 0 auto;
      position: relative;
    }
  }
}

.headerLeft {
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 968px) {
    flex-direction: column;
  }
}
