@mixin buttonSizeColor($width, $height, $color) {
  width: $width;
  height: $height;
  background-color: $color;
  &:hover {
    opacity: 0.7;
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: transparent;
  transition: 0.3s;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.headerBtn {
  @include buttonSizeColor(217px, 100px, #f12c32);
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  margin-left: 80px;
  @media only screen and (max-width: 1200px) {
    margin-left: 50px;
  }
}

.buttonCard {
  @include buttonSizeColor(207px, 52px, #f12c32);
  border-radius: 26px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #f4ebff;
  text-transform: uppercase;
  position: absolute;
  right: 0;
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.buttonCardMobile {
  @include buttonSizeColor(100%, 30px, #f12c32);
  border-radius: 26px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #f4ebff;
  text-transform: uppercase;
  @media only screen and (max-width: 450px) {
    height: 23px;
  }
}

.btnSidebar {
  @include buttonSizeColor(34px, 34px, #f12c32);
  border-radius: 50%;
  display: none;
  div {
    background-color: #ffffff;
    &:nth-child(2) {
      margin: 0 3px;
    }
  }
  @media only screen and (max-width: 968px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
}

.sidebarBtn {
  @include buttonSizeColor(100%, 100px, #f12c32);
  border: transparent;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

.sidebarBtnGrey {
  @include buttonSizeColor(100%, 100px, #f5f5f5);
  border: transparent;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: bold;
  font-style: italic;
  text-align: center;
  color: #f12c32;
}

.buttonInfoHeader {
  @include buttonSizeColor(207px, 52px, #f12c32);
  border-radius: 26px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #f4ebff;
  text-transform: uppercase;
  @media only screen and (max-width: 450px) {
    @include buttonSizeColor(130px, 40px, #f12c32);
    font-size: 12px;
  }
  @media only screen and (max-width: 320px) {
    @include buttonSizeColor(187px, 46px, #f12c32);
    margin-top: 15px;
  }
}

.buttonLogIn {
  @include buttonSizeColor(50%, 52px, #f12c32);
  border-radius: 26px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  color: #f4ebff;
  &:disabled {
    opacity: 0.2;
  }
  &:hover {
    cursor: pointer;
    background-color: #2cf1eb;
  }
}

.buttonLogin {
  @include buttonSizeColor(50%, 52px, #f12c32);
  border-radius: 26px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  color: #f4ebff;
  &:disabled {
    opacity: 0.2;
  }
}

.buttonSignIn {
  @include buttonSizeColor(100%, 52px, #f12c32);
  border-radius: 26px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  color: #f4ebff;
  &:disabled {
    opacity: 0.2;
  }
}

.buttonForgotPass {
  @include buttonSizeColor(50%, 52px, transparent);
  font-size: 18px;
  font-weight: bold;
  font-style: italic;
  color: #f12c32;
  text-transform: capitalize;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &:hover {
    opacity: 0.7;
  }
  @media only screen and (max-width: 450px) {
    font-size: 17px;
  }
}

.buttonTransparentColor {
  background: transparent;
  font-size: 18px;
  font-weight: bold;
  font-style: italic;
  color: #f12c32;
  text-transform: capitalize;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &:hover {
    opacity: 0.7;
  }
}

.btnBlackTransparent {
  background: transparent;
  font-size: 16px;
  font-weight: bold;
  color: #2d2d2d;
  font-style: italic;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  &:hover {
    opacity: 0.7;
  }
}

.btnBlueTransparent {
  background: transparent;
  font-size: 16px;
  font-weight: bold;
  color: #07727d;
  font-style: italic;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  text-decoration: underline;
  &:hover {
    opacity: 0.7;
  }
}

.checkButtonActive {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: solid 2px #000000;
  background-color: rgba(241, 44, 50, 0.1);
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #f12c32;
    margin: 0 10px 0 8px;
  }
  span {
    &::first-letter {
      text-transform: capitalize;
    }
  }
  @media only screen and (max-width: 450px) {
    font-size: 14px;
  }
}

.checkButtonDisable {
  @include buttonSizeColor(100%, 52px, transparent);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: solid 2px #898989;
  font-size: 16px;
  font-weight: 500;
  color: #898989;
  div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #898989;
    margin: 0 10px 0 8px;
  }
  span {
    &::first-letter {
      text-transform: capitalize;
    }
  }
}

.buttonBAackToLogIn {
  @include buttonSizeColor(240px, 52px, #f12c32);
  width: 240px;
  height: 52px;
  background-color: #f12c32;
  border-radius: 26px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  color: #f4ebff;
  &:disabled {
    opacity: 0.2;
  }
  @media only screen and (max-width: 450px) {
    font-size: 14px;
    width: 100%;
  }
}

.buttonSave {
  @include buttonSizeColor(50%, 52px, #f12c32);
  border-radius: 26px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  color: #f4ebff;
  &:disabled {
    opacity: 0.2;
  }
  @media only screen and (max-width: 450px) {
    width: 100%;
  }
}

.buttonSaveContinue {
  @include buttonSizeColor(267px, 52px, #f12c32);
  border-radius: 26px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  color: #f4ebff;
  &:disabled {
    opacity: 0.2;
  }
}

.buttonCreateNew {
  @include buttonSizeColor(213px, 52px, #f12c32);
  border-radius: 26px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  color: #f4ebff;
}

.ordersMenu {
  background-color: transparent;
  padding: 5px 20px;
  font-size: 20px;
  font-weight: bold;
  // font-style: italic;
  color: rgb(0, 0, 0);
  text-transform: capitalize;

  &:hover {
    opacity: 0.5;
  }
}

.ordersMenuActivity {
  background-color: #f12c32;
  padding: 5px 20px;
  font-size: 20px;
  font-weight: bold;
  // font-style: italic;
  color: rgba(255, 255, 255, 1);
  text-transform: capitalize;
}

.buttonCardPublication {
  @include buttonSizeColor(100%, 23px, #2d2d2d);
  border-radius: 3px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  @media only screen and (max-width: 450px) {
    height: 35px;
  }
}

.btnCardPubMini {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 19px 14px;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  border: solid 3px #f12c32;
  background-color: #ffffff;
  img {
    width: 57px;
    height: 57px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    margin-right: 20px;
  }
  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  span {
    font-size: 20px;
    font-style: italic;
    color: #2d2d2d;
    text-transform: capitalize;
    &:last-child {
      font-size: 12px;
      color: rgba(45, 45, 45, 0.75);
      margin-top: 10px;
    }
  }
}

.buttonTransparentRed {
  background: transparent;
  font-size: 18px;
  font-weight: bold;
  font-style: italic;
  color: #f12c32;
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
  align-items: center;
  &:hover {
    opacity: 0.7;
  }
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
}

.buttonDate {
  width: 160px;
  height: 46px;
  padding: 13px 15px 13px 13px;
  border: solid 3px #898989;
  background-color: #ffffff;
  @media only screen and (max-width: 450px) {
    width: 130px;
  }
  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 15px;
      font-weight: 500;
      color: #898989;
      text-transform: uppercase;
    }
    svg {
      color: #898989;
    }
  }
}

.activeBtnDate {
  width: 160px;
  height: 46px;
  padding: 13px 15px 13px 13px;
  border: 3px solid #00897b;
  background-color: #ffffff;
  @media only screen and (max-width: 450px) {
    width: 120px;
  }
  span {
    font-size: 15px;
    font-weight: 500;
    color: #00897b;
    text-transform: uppercase;
    white-space: nowrap;
    @media only screen and (max-width: 450px) {
      font-size: 12px;
    }
  }
}

.chooseDate {
  width: 109px;
  height: 38px;
  padding: 9px 9px;
  border-radius: 5px;
  border: 3px solid transparent;
  background-color: #f2f1f1;
  margin-right: 11px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  opacity: 0.8;
  white-space: nowrap;
  cursor: pointer;
}

.choosed {
  width: 109px;
  height: 38px;
  padding: 9px 10px;
  border-radius: 5px;
  background-color: #f2f1f1;
  color: #00897b;
  border: 3px solid #00897b;
  margin-right: 11px;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  cursor: pointer;
}

.addDate {
  width: auto;
  height: 15px;
  font-size: 13px;
  font-weight: 500;
  font-style: italic;
  color: #898989;
  opacity: 0.8;
  background-color: transparent;
  margin-top: 40px;
  justify-content: flex-start;
  text-transform: capitalize;
}

.uploadBtn {
  width: 100%;
  padding: 39px 140px 39px 141px;
  border: solid 2px #898989;
  background: transparent;
  img {
    width: 52px;
    height: 52px;
  }
}

.btnImageViewUpload {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  border: solid 1px #979797;
  background-color: #ffffff;
}

.btnDateFilter {
  background-color: transparent;
  font-size: 14px;
  color: #2d2d2d;
  letter-spacing: 0.47px;
  background-color: transparent;
  border-bottom: 1px solid transparent;
}

.btnDateFilterActive {
  color: #f12c32;
  border-bottom: 1px solid #f12c32;
}

.buttonDayChoose {
  width: 109px;
  height: 58px;
  border: 3px solid #b7c4d2;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 12px;
    letter-spacing: 0.4px;
    text-align: center;
    text-transform: uppercase;
    color: rgba(45, 45, 45, 0.75);
    &:first-child {
      font-size: 17px;
      letter-spacing: 0.57px;
      text-align: center;
      color: #2d2d2d;
    }
    &:last-child {
      font-size: 10px;
      letter-spacing: 0.33px;
      text-align: center;
      color: rgba(45, 45, 45, 0.75);
    }
  }
}

.buttonDayChoosePast {
  @extend .buttonDayChoose;

  pointer-events: none;
  background-color: #cecece;
  border-color: #979797;
}

.buttonDayChooseActive {
  border: 3px solid #f12c32;
  span {
    color: #f12c32;
    &:first-child {
      color: #f12c32;
    }
    &:last-child {
      color: #f12c32;
    }
  }
}

.buttonDayChooseUnavailable {
  width: 109px;
  height: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 3px solid #393939;
  background-color: #5f5f5f;
  pointer-events: none;
  span {
    font-size: 12px;
    letter-spacing: 0.4px;
    text-align: center;
    text-transform: uppercase;
    color: #d8d8d8;
    &:first-child {
      font-size: 17px;
      letter-spacing: 0.57px;
      text-align: center;
      color: #d8d8d8;
    }
    &:last-child {
      font-size: 10px;
      letter-spacing: 0.33px;
      text-align: center;
      color: #d8d8d8;
    }
  }
}

.btnCalendarNavigation {
  font-size: 20px;
  letter-spacing: 0.67px;
  text-align: right;
  color: #2d2d2d;
  opacity: 0.5;
  padding: 0 0 8px;
  text-transform: uppercase;
  background: transparent;
  @media only screen and (max-width: 320px) {
    font-size: 16px;
  }
}

.payNowButton {
  @include buttonSizeColor(100%, 52px, #f12c32);
  border-radius: 26px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #f4ebff;
  text-transform: uppercase;
  &:disabled {
    opacity: 0.2;
  }
  @media only screen and (max-width: 450px) {
    @include buttonSizeColor(100%, 40px, #f12c32);
    font-size: 12px;
  }
  @media only screen and (max-width: 320px) {
    @include buttonSizeColor(100%, 46px, #f12c32);
    margin-top: 15px;
  }
}

.payCardActive {
  width: 100%;
  height: 35px;
  padding: 9px 9px;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  border: 2px solid rgba(210, 213, 219, 0.8);
  cursor: pointer;
}

.blueSubmit {
  width: 100%;
  height: 35px;
  padding: 9px 9px;
  border-radius: 5px;
  background-color: #566cd6;
  color: #ffffff;
  font-size: 16px;
  &:first-letter {
    text-transform: capitalize;
  }
}

.btnPdfInvoice {
  width: 60px;
  height: 30px;
  font-size: 16px;
  line-height: 1.67;
  letter-spacing: normal;
  color: rgba(45, 45, 45, 0.75);
  text-transform: uppercase;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.2);
  border: 2px solid rgba(245, 245, 245, 0.8);
}

.btnModalZip {
  background: transparent;
}

.hide {
  display: none;
}
