$spinkit-size: 50px !default;
$spinkit-spinner-color: #f12c32 !default;


.skFadingCircle {
  $circleCount: 12;
  $animationDuration: 1.2s;
  width: $spinkit-size;
  height: $spinkit-size;
  position: relative;
  margin: auto;
  .skCircle {
    width: 90%;
    height: 90%;
    position: absolute;
    left: 0%;
    top: 0%;
    @media only screen and (max-width: 450px) {
      width: 60%;
      height: 60%;
      left: 20%;
      top: 20%;
    }
  }

  .skCircle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 10%;
    height: 10%;
    background-color: $spinkit-spinner-color;
    border-radius: 100%;
    animation: skFadingCircleDelay $animationDuration infinite ease-in-out both;
  }

  @for $i from 2 through $circleCount {
    .skCircle_#{$i} {
      transform: rotate((360deg / $circleCount * ($i - 1)));
    }
  }

  @for $i from 2 through $circleCount {
    .skCircle_#{$i}:before {
      animation-delay: (-$animationDuration + $animationDuration / $circleCount * ($i - 1));
    }
  }

}

@keyframes skFadingCircleDelay {
  0%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
