.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  .linkMenu {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    font-style: italic;
    line-height: 2.5;
    color: rgba(45, 45, 45, 0.75);
    text-transform: capitalize;
    margin-left: 20px;
    &:first-child {
      display: none;
    }
    span {
      margin-left: 15px;
    }
  }
  button {
    margin-bottom: 20px;
  }
}
